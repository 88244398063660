.ms-ProgressIndicator {
  width: 800px;
}

.background-download-agent {
  //background: hsla(186, 33%, 94%, 1);

  //background: linear-gradient(90deg, hsla(186, 33%, 94%, 1) 0%, hsla(216, 41%, 79%, 1) 100%);

  //background: -moz-linear-gradient(90deg, hsla(186, 33%, 94%, 1) 0%, hsla(216, 41%, 79%, 1) 100%);

  //background: -webkit-linear-gradient(90deg, hsla(186, 33%, 94%, 1) 0%, hsla(216, 41%, 79%, 1) 100%);
  background: white;

  //filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#EBF4F5", endColorstr="#B5C6E0", GradientType=1 );
}

.background-report-summary {
  background: #EDEBE9;
  line-height: 30px;
  padding: 0 5px;
  width: 100%;
  margin-right: 15px;
  margin-bottom: 20px;
  //filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#EBF4F5", endColorstr="#B5C6E0", GradientType=1 );
}


.background-download-agent-title {
  line-height: 40px;
  padding: 0 10px;
  //border-bottom: 1px solid #9FB5D7;
  background: #EDEBE9;

  //filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#BEB15B", endColorstr="#D5CFAC", GradientType=1 );
}

.hov-pointer {
  cursor: pointer;
}

.hidden {
  visibility: hidden;
}

.download {
  color: inherit;
  text-decoration: none;
}
