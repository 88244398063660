
.access-azure-card {
  margin-top: 1px;
  width: 250px;
  height: 345px;
  border: 1px solid #edede9
}

.mainframe-component {
  margin-top: 1px;
  margin-left: 5px;
  width: 250px;
  height: 345px;
  border: 1px solid #edede9
  //filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#EBF4F5", endColorstr="#B5C6E0", GradientType=1 );
}

.mainframe-component-title {
  line-height: 40px;
  padding: 0 10px;
  border-bottom: 1px solid #9FB5D7;
  background: hsla(233, 100%, 90%, 1);
  background: linear-gradient(360deg, hsla(233, 100%, 90%, 1) 0%, hsla(0, 0%, 89%, 1) 100%);
  background: -moz-linear-gradient(360deg, hsla(233, 100%, 90%, 1) 0%, hsla(0, 0%, 89%, 1) 100%);
  background: -webkit-linear-gradient(360deg, hsla(233, 100%, 90%, 1) 0%, hsla(0, 0%, 89%, 1) 100%);
}

.circleBase {
  border-radius: 50%;
}
