
  .background-setup-instruction {
    margin-top: 5px;
    width: 250px;
    height: 98%;
    background: white;
    border: 1px solid #EDEBE9;
  }

  .background-setup-download {
    background: white;
    //filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#EBF4F5", endColorstr="#B5C6E0", GradientType=1 );
  }

  .circleBase {
    border-radius: 50%;
  }

  .hov-pointer{
    cursor: pointer;
  }
