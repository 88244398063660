.ms-DetailsList {
  margin-top: -25px !important;
}

//.ms-DetailsHeader-cellTitle {
//  border-right: 1px solid rgb(200, 198, 196) !important;
//}
//.ms-DetailsList-headerWrapper {
//  border-top: 1px solid rgb(200, 198, 196) !important;
//  border-bottom: 1px solid rgb(200, 198, 196) !important;
//}